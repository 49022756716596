@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-warning {
    width: 100%;
    display: block;
    position: fixed;
    border-bottom: 1px solid #fff;
    top: 0;
    z-index: 9999999; }
    .ie-warning .ie-warning-msg {
      background-color: #e33b1f;
      text-align: center;
      padding: .5rem;
      height: 61px; } }
    @media screen and (-ms-high-contrast: active) and (max-width: 850px), screen and (-ms-high-contrast: none) and (max-width: 850px) {
      .ie-warning .ie-warning-msg {
        height: 81px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .ie-warning .ie-warning-msg p {
        padding: .1rem;
        margin: 0;
        color: #ffffff; }
      .ie-warning .ie-warning-msg .ie-warning-header {
        font-weight: bold;
        font-size: large;
        margin: 0;
        animation: 2000ms ease 0s normal none infinite pulsate-ie-warning; }
    .ie-warning [data-content]:before {
      content: attr(data-content); }
  #header {
    margin-top: 61px; } }
  @media screen and (-ms-high-contrast: active) and (max-width: 850px), screen and (-ms-high-contrast: none) and (max-width: 850px) {
    #header {
      margin-top: 81px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    padding-top: 18rem !important; } }

@keyframes pulsate-ie-warning {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }
